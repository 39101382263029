<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="breadcrumb">
      <h1 class="mb-0"><strong>Servicios Complementarios</strong></h1>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-12 text-center" style="color: orange;">
                IMAGEONOLOGIA
              </div>
              <div class="col-sm-3 text-right" style="padding: 1%;">
                <button type="button" class="btn mr-2"
                :class="isFarmacia ? 'btn-orange' : 'btn-secondary'"
                @click="componetShow='Farmacia'">
                  Farmacia
                </button>
                <button type="button" class="btn mr-2"
                :class="isLaboratorio ? 'btn-orange' : 'btn-secondary'"
                @click="componetShow='Laboratorio'">
                  Laboratorio
                </button>
              </div>
              <div class="col-6 col-sm-7 text-left border-button-orange">
                <button
                  type="button"
                  class="btn mr-2"
                  :class="isEcografia ? 'btn-orange': 'btn-secondary'"
                  @click="componetShow='Ecografia'"
                >
                  {{getButton1Text}}
                </button>
                <button
                  type="button"
                  class="btn mr-2"
                  :class="isRayosX ? 'btn-orange' : 'btn-secondary'"
                  @click="componetShow='RayosX'"
                >
                  {{getButton2Text}}
                </button>
                <button
                  type="button"
                  class="btn mr-2"
                  :class="isTomografia ? 'btn-orange' : 'btn-secondary'"
                  @click="componetShow='Tomografia'"
                >
                  {{getButton3Text}}
                </button>
                <button
                  type="button"
                  class="btn mr-2"
                  :class="isMamografia ? 'btn-orange' : 'btn-secondary'"
                  @click="componetShow='Mamografia'"
                >
                  {{getButton4Text}}
                </button>
                <button
                  type="button"
                  class="btn"
                  :class="isArcoC ? 'btn-orange' : 'btn-secondary'"
                  @click="componetShow='ArcoC'"
                >
                  {{getButton5Text}}
                </button>
              </div>
              <div class="col-6 col-sm-5 col-md-4 col-lg-4" id="responsiveColumn">
                <button type="button" class="btn mr-2"
                :class="isFisioterapia ? 'btn-orange' : 'btn-secondary'"
                @click="componetShow='Fisioterapia'">
                  Fisioterapia
                </button>
                <button type="button" class="btn mr-2"
                :class="isTratamiento ? 'btn-orange' : 'btn-secondary'"
                @click="componetShow='Tratamiento'">
                  Tratamiento
                </button>
                <button type="button" class="btn mr-2"
                :class="isTextos ? 'btn-orange' : 'btn-secondary'"
                @click="componetShow='Textos'">
                  Textos
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <TheFarmacia
              v-if="componetShow === 'Farmacia'"
              :id="categoriaFarmacia"
            />
            <TheLaboratorio
              v-if="componetShow === 'Laboratorio'"
              :id="categoriaLaboratorio"
            />

            <TheEcografia
              v-if="componetShow === 'Ecografia'"
              :id="categoriaEcografia"
            />
            <TheRayosX
              v-if="componetShow === 'RayosX'"
              :id="categoriaRayosX"
            />
            <TheTomografia
              v-if="componetShow === 'Tomografia'"
              :id="categoriaTomografia"
            />
            <TheMamografia
              v-if="componetShow === 'Mamografia'"
              :id="categoriaMamografia"
            />
            <TheArcoEnC
              v-if="componetShow === 'ArcoC'"
              :id="categoriaArcoC"
            />

            <TheFisioterapia
              v-if="componetShow === 'Fisioterapia'"
              :id="categoriaFisioterapia"
            />
            <TheTratamiento
              v-if="componetShow === 'Tratamiento'"
              :id="categoriaTratamiento"
            />
            <TheTextos
              v-if="componetShow === 'Textos'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { CATEGORIA_SERVICIO_COMPL } from '@/utils/globalConventions';
import TheFarmacia from '../components/TheFarmacia.vue';
import TheEcografia from '../components/TheEcografia.vue';
import TheRayosX from '../components/TheRayosX.vue';
import TheTomografia from '../components/TheTomografia.vue';
import TheMamografia from '../components/TheMamografia.vue';
import TheArcoEnC from '../components/TheArcoEnC.vue';
import TheLaboratorio from '../components/TheLaboratorio.vue';
import TheTextos from '../components/TheTextos.vue';
import TheFisioterapia from '../components/TheFisioterapia.vue';
import TheTratamiento from '../components/TheTratamiento.vue';

export default {
  name: 'index',
  data() {
    return {
      componetShow: 'Farmacia',
      isLoading: true,
      seccionesImageonologia: [],
    };
  },
  created() {
    this.getInitialData();
  },
  methods: {
    async getInitialData() {
      this.isLoading = true;
      try {
        const response = await axios.get('/clinic/servicio_complementario/servicios/create');
        this.seccionesImageonologia = response.data.data.servicio_complementario_categoria;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    isFarmacia() {
      return (this.componetShow === 'Farmacia');
    },
    isLaboratorio() {
      return (this.componetShow === 'Laboratorio');
    },
    isEcografia() {
      return (this.componetShow === 'Ecografia');
    },
    isRayosX() {
      return (this.componetShow === 'RayosX');
    },
    isTomografia() {
      return (this.componetShow === 'Tomografia');
    },
    isFisioterapia() {
      return (this.componetShow === 'Fisioterapia');
    },
    isArcoC() {
      return (this.componetShow === 'ArcoC');
    },
    isMamografia() {
      return (this.componetShow === 'Mamografia');
    },
    isTratamiento() {
      return (this.componetShow === 'Tratamiento');
    },
    isTextos() {
      return (this.componetShow === 'Textos');
    },
    getButton1Text() {
      const idEcografia = this.categoriaEcografia;
      if (this.seccionesImageonologia.length > 0) {
        const item = this.seccionesImageonologia.find((el) => el.id === idEcografia) ?? null;
        if (item !== null) {
          return item.nombre;
        }
      }
      return '';
    },
    getButton2Text() {
      const idEcografia = this.categoriaRayosX;
      if (this.seccionesImageonologia.length > 0) {
        const item = this.seccionesImageonologia.find((el) => el.id === idEcografia) ?? null;
        if (item !== null) {
          return item.nombre;
        }
      }
      return '';
    },
    getButton3Text() {
      const idEcografia = this.categoriaTomografia;
      if (this.seccionesImageonologia.length > 0) {
        const item = this.seccionesImageonologia.find((el) => el.id === idEcografia) ?? null;
        if (item !== null) {
          return item.nombre;
        }
      }
      return '';
    },
    getButton4Text() {
      const idEcografia = this.categoriaMamografia;
      if (this.seccionesImageonologia.length > 0) {
        const item = this.seccionesImageonologia.find((el) => el.id === idEcografia) ?? null;
        if (item !== null) {
          return item.nombre;
        }
      }
      return '';
    },
    getButton5Text() {
      const idEcografia = this.categoriaArcoC;
      if (this.seccionesImageonologia.length > 0) {
        const item = this.seccionesImageonologia.find((el) => el.id === idEcografia) ?? null;
        if (item !== null) {
          return item.nombre;
        }
      }
      return '';
    },
    categoriaFisioterapia() {
      return CATEGORIA_SERVICIO_COMPL.FISIOTERAPIA;
    },
    categoriaTratamiento() {
      return CATEGORIA_SERVICIO_COMPL.TRATAMIENTO;
    },
    categoriaArcoC() {
      return CATEGORIA_SERVICIO_COMPL.ARCO_C;
    },
    categoriaMamografia() {
      return CATEGORIA_SERVICIO_COMPL.MAMOGRAFIA;
    },
    categoriaTomografia() {
      return CATEGORIA_SERVICIO_COMPL.TOMOGRAFIA;
    },
    categoriaRayosX() {
      return CATEGORIA_SERVICIO_COMPL.RAYOS_X;
    },
    categoriaEcografia() {
      return CATEGORIA_SERVICIO_COMPL.ECOGRAFIA;
    },
    categoriaFarmacia() {
      return CATEGORIA_SERVICIO_COMPL.FARMACIA;
    },
    categoriaLaboratorio() {
      return CATEGORIA_SERVICIO_COMPL.LABORATORIO;
    },
  },
  components: {
    TheFarmacia,
    TheLaboratorio,
    TheEcografia,
    TheRayosX,
    TheTomografia,
    TheMamografia,
    TheArcoEnC,
    TheTextos,
    TheFisioterapia,
    TheTratamiento,
  },
};
</script>

<style>
.btn-orange {
  color: white;
  background: #F4B375;
}
.border-button-orange {
  border: 1px orange solid;
  padding: 1%;
  max-width: max-content;
}
@media (max-width: 1078px) {
  #responsiveColumn {
    padding: 1%;
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }
}

@media (min-width: 1079px) and (max-width: 1092px) {
  #responsiveColumn {
    padding: 1%;
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }
}

@media (min-width: 1093px) {
  #responsiveColumn {
    padding: 1%;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}
</style>
